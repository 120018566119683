"use strict";

// (() => {
//   const products = [
//     {
//       name: "DPF Master Flash Professional PLUS",
//       image: 'https://arightim.sirv.com/app/dpf-master-flash-professional-plus.webp',
//       attributes: [
//         's-1' = [
//           ' s-1-o-1', 's-1-o-2', 's-2-o-1'
//           ]
//       ]
//     },
//     {
//       name: "DPF Master Flash Expert",
//       image: 'https://arightim.sirv.com/app/dpf-master-flash-expert.webp',
//       attributes: [
//         's-1-o-1', 's-1-o-2', 's-2-o-1'
//       ]
//     },
//     {
//       name: "DPF Master Flash Professional",
//       image: 'https://arightim.sirv.com/app/dpf-master-flash-professional.webp',
//       attributes: [
//         's-1-o-1', 's-1-o-2', 's-2-o-1'
//       ]
//     },
//     {
//       name: "DPF Master Flash Standard",
//       image: 'https://arightim.sirv.com/app/dpf-master-flash-standard.webp',
//       attributes: [
//         's-1-o-1', 's-1-o-2', 's-2-o-1'
//       ]
//     },
//   ]

//   products.forEach((item) => {
//     const element = document.createElement('div');

//     element.classList.add('bg-[#F0F0F0]', 'p-5');

//     element.innerHTML = `
//       <img src="${item.image}" class="h-[250px] object-contain object-center" alt="">
//       <h4 class="font-kanit text-base text-center leading-none">${item.name}</h4>
//     `;

//     const container = document.querySelector('#configurator-products-container');

//     container.append(element);
//   });

//   const form = document.querySelector('#configurator-form');

//   form.addEventListener('submit', (event) => {
//     event.preventDefault();
//     console.log(form.values());
//   })
// })();

(() => {
  const faqContainer = document.querySelector('#faq-container');

  if ( typeof faqContainer != 'undefined' && faqContainer != null ) {
    const items = faqContainer.querySelectorAll('.faq-item'); 

    function toggleFaq(item) {
      const iconToggleClass = 'rotate-180';
      const itemToggleClass = 'active';
      const answearToggleClass = 'hidden';

      items.forEach((itemFoo) => {
        itemFoo.classList.remove(itemToggleClass);
        itemFoo.querySelector('.faq-item__icon').classList.add(iconToggleClass);
        itemFoo.querySelector('.faq-item__answear').classList.add(answearToggleClass);
      })

      if ( !item.classList.contains(itemToggleClass) ) {
        item.classList.add(itemToggleClass);
        item.querySelector('.faq-item__icon').classList.remove(iconToggleClass);
        item.querySelector('.faq-item__answear').classList.remove(answearToggleClass);
      } else {
        item.classList.remove(itemToggleClass);
        item.querySelector('.faq-item__icon').classList.add(iconToggleClass);
        item.querySelector('.faq-item__answear').classList.add(answearToggleClass);
      }
    }

    toggleFaq(items[0]);

    items.forEach((item) => {
      const toggler = item.querySelector('.faq-item__question');

      toggler.addEventListener('click', (e) => {
        e.preventDefault();
        toggleFaq(item);
      });
    });
  }
})();

// (() => {
//   const contactForm = document.querySelector('#dpfmasterflash-contact-form');

//   if ( typeof contactForm != 'undefined' && contactForm != null ) {
//     const formElements = contactForm.querySelectorAll("[required]");
//     const leadComplianceInput = contactForm.querySelector('#lead-compliance');
//     const submitBtn = contactForm.querySelector('button[type="submit"]');

//     submitBtn.addEventListener('click', (e) => {
//       e.preventDefault();

//       if ( leadComplianceInput.checked ) {
//         contactForm.submit();
//       } else {
//         alert("Zatwierdź zgody RODO");
//       }
//     })
//   }
// })();

/**
 * Gets the value of a specified cookie by name.
 * @param {string} name - The name of the cookie to retrieve.
 * @return {string|null} The value of the cookie if found; otherwise, null.
 */
function getCookie(name) {
    const nameEQ = `${encodeURIComponent(name)}=`;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let c = cookies[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length); // Trim leading whitespace
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

/**
 * Sets a cookie.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} [days] - The number of days until the cookie expires. If not provided, creates a session cookie.
 * @param {string} [path='/'] - The path on the domain where the cookie is valid.
 * @param {string} [domain] - The domain where the cookie is valid.
 * @param {boolean} [secure=false] - If true, the cookie will only be sent over secure (HTTPS) connections.
 */
function setCookie(name, value, days, path = '/', domain, secure = false) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }

    const pathValue = `; path=${path}`;
    const domainValue = domain ? `; domain=${domain}` : '';
    const secureFlag = secure ? '; secure' : '';

    document.cookie = `${name}=${value}${expires}${pathValue}${domainValue}${secureFlag}`;
}

/**
 * Deletes a cookie.
 * @param {string} name - The name of the cookie to delete.
 * @param {string} [path='/'] - The path where the cookie is valid. Important to specify if it was specified when the cookie was set.
 * @param {string} [domain] - The domain where the cookie is valid. Important to specify if it was specified when the cookie was set.
 */
function deleteCookie(name, path = '/', domain) {
    const expires = 'Thu, 01 Jan 1970 00:00:00 GMT'; // Date in the past
    const pathValue = `; path=${path}`;
    const domainValue = domain ? `; domain=${domain}` : '';
    document.cookie = `${name}=; expires=${expires}${pathValue}${domainValue};`;
}

/**
 * Checks if a cookie is set.
 * @param {string} name - The name of the cookie to check.
 * @returns {boolean} True if the cookie is set; otherwise, false.
 */
function isCookieSet(name) {
    return getCookie(name) !== null;
}

const cookieName = "popup-97365";

const url = window.location.href;

const searchParams = new URLSearchParams(new URL(url).search);


if ( searchParams.get('source') === `facebook_ad` ) {
    window.location.replace('https://maszynadodpf.pl#uzyskaj-oferte');
}

window.addEventListener('init', () => {
  const video = document.querySelectorAll('video');

  video.forEach(item => {
    item.play();
  });
});

const myPopup = new Popup({
    id: "exit-intent-popup",
    title: "Nie przegap najlepszej oferty na rynku!",
    content: `
    <div class="grid grid-cols-1 md:grid-cols-2">
        <div>
            <h3>Z XTON nic nie ryzykujesz...</h3>
            <p><strong>A możesz jedynie zyskać!</strong> Skorzystaj z formularza na stronie i daj się przekonać, że Wynajem All Inclusive™ od XTON to najbardziej korzystna oferta finansownaia inwestycji w serwisie lub przedsiębiorstwie.</p>
            <h4 style="font-size: 16px;">Wynajem All Inslusive to:</h4>
            <ul>
                <li style="display: flex; align-items: center;">
                    <span style="font-size: 15px; line-height: 120%;">1. Gwarancja stałej opłaty miesięcznej</span>
                </li>
                <li style="display: flex; align-items: center;">
                    <span style="font-size: 15px; line-height: 120%;">2. Możliwość rozwiązania umowy i zwrotu urządzenia w dowolnym momencie</span>
                </li>
                <li style="display: flex; align-items: center;">
                    <span style="font-size: 15px; line-height: 120%;">3. Cykliczna dostawa materiałów eksploatacyjnych</span>
                </li>
                <li style="display: flex; align-items: center;">
                    <span style="font-size: 15px; line-height: 120%;">4. Gwarancja producenta przez 48 mies.</span>
                </li>
            </ul>
        </div>
        <div><img src="./DPF300.ertigb344.webp"; style="width: 100%;" alt="" /></div>
    </div>
    <a href="#uzyskaj-oferte" class="btn btn--primary" id="popup-button" style="font-weight: 400; margin: 0 auto;">Uzyskaj niezobowiązującą ofertę</a>`,
    borderRadius: '0px',
    css: `
        .popup-title {
            font-family: "Russo One", sans-serif;
            font-size: 28px;
        }

        .popup-body {
            font-family: "Kanit", sans-serif;
            line-height: 120%;
        }

        .popup-body .grid > p {
            display: none !important;
        }

        .popup-body .grid > div {
            text-align: left !important;
        }

        .popup-body .grid > div > h3 {
            font-size: 20px;
        }

        .popup-body .grid > div > p {
            font-size: 15px;
        }

        .popup.exit-intent-popup .popup-body {
            padding-left: 6%;
            padding-right: 6%;
        }

        .popup-body ul > li p {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            line-height: 45% !important;
        }
    `,
    showOnce: true,
    titleMargin: "10px",
    loadCallback: () => {
        document.querySelector('#popup-button').addEventListener('click', (e) => {
            e.preventDefault();

            window.location.replace('https://maszynadodpf.pl/#uzyskaj-oferte');
        })
    },
    hideCallback: () => {
        setCookie(cookieName, 'popup-seen', 3);
    },
});

if ( !isCookieSet(cookieName) ) {
    document.addEventListener('mouseleave', function(event) {
        if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
            myPopup.show();
            setCookie(cookieName, 'popup-seen', 3);
        }
    });
}